import React, { useEffect, useState } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import ReactGA from "react-ga4";

const TRACKING_ID = "G-CB1DX3Y9Y4";
const DELAY_TIME = 55000; // 55 seconds delay
function Layout() {
    const location = useLocation();
    const [hasDelayPassed, setHasDelayPassed] = useState(false);
    
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setHasDelayPassed(true);
      }, DELAY_TIME);
  
      return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
      if (hasDelayPassed && !CookieConsent.validConsent()) {

        /**
         * All config. options available here:
         * https://cookieconsent.orestbida.com/reference/configuration-reference.html
         */
        CookieConsent.run({

            categories: {
                necessary: {
                    enabled: true,  // this category is enabled by default
                    readOnly: true  // this category cannot be disabled
                },
                analytics: {}
            },

            language: {
                default: 'en',
                translations: {
                    en: {
                        consentModal: {
                            title: 'Hello traveller, we use cookies.',
                            description: 'Our website uses tracking cookies to understand how you interact with it. The tracking will be enabled only if you accept explicitly. <a href="#privacy-policy" data-cc="show-preferencesModal" class="cc__link">Manage preferences</a>',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            showPreferencesBtn: 'Manage Individual preferences'
                        },
                        preferencesModal: {
                            title: 'Manage cookie preferences',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            savePreferencesBtn: 'Accept current selection',
                            closeIconLabel: 'Close',
                            sections: [
                                {
                                    title: 'Somebody said ... cookies?',
                                    description: 'I want one!'
                                },
                                {
                                    title: 'Strictly Necessary cookies',
                                    description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                                    //this field will generate a toggle linked to the 'necessary' category
                                    linkedCategory: 'necessary'
                                },
                                {
                                    title: 'Performance and Analytics',
                                    description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                                    linkedCategory: 'analytics'
                                },
                                {
                                    title: 'More information',
                                    description: 'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>'
                                }
                            ]
                        }
                    }
                }
            },


            // onFirstConsent: ({cookie}) => {
            //     console.log('onFirstConsent fired',cookie);
            // },

            // onConsent: ({cookie}) => {
            //     console.log('onConsent fired!', cookie)
            // },

            // onChange: ({changedCategories, changedServices}) => {
            //     console.log('onChange fired!', changedCategories, changedServices);
            // },

            // onModalReady: ({modalName}) => {
            //     console.log('ready:', modalName);
            // },

            // onModalShow: ({modalName}) => {
            //     console.log('visible:', modalName);
            // },

            // onModalHide: ({modalName}) => {
            //     console.log('hidden:', modalName);
            // },
        });
        setHasDelayPassed(false);
      }
    }, [hasDelayPassed]);
  
    useEffect(() => {
        if (process.env.NODE_ENV === "production") {
          ReactGA.initialize(TRACKING_ID);
          ReactGA.send({ hitType: "pageview", page: location.pathname });
        }
    }, [location.pathname]);

    // This function creates stars dynamically and appends them to the container
    const createStars = () => {
        const starsContainer = document.querySelector('.stars');
        if (starsContainer) {
        for (let i = 0; i < 100; i++) {
            const star = document.createElement('div');
            star.classList.add('star');
            star.style.width = `${Math.random() * 3}px`;
            star.style.height = star.style.width;
            star.style.left = `${Math.random() * 100}%`;
            star.style.top = `${Math.random() * 100}%`;
            star.style.animationDuration = `${Math.random() * 3 + 1}s`;
            star.style.animationDelay = `${Math.random() * 3}s`;
            starsContainer.appendChild(star);
        }
        }
    };

    // useEffect to call createStars on component mount
    useEffect(() => {
        createStars();
    }, []); // Empty dependency array ensures it runs once when the component mounts

    return (
        <div className="background">
            <div className="stars"></div>
            <main>
                <Outlet />
            </main>
            <footer>
                <nav className="footer-nav">
                    <Link to="/">Home</Link>
                    <Link to="/about">About</Link>
                    <Link to="/contact">Contact</Link>
                    <Link to="/terms">Terms</Link>
                    <Link to="/privacy">Privacy</Link>
                </nav>
                <p style={{textAlign: "center", marginBottom: "30px"}}>&copy; {new Date().getFullYear()} Reed Walson Enterprises, LLC. <br /> All Rights Reserved.</p>
            </footer>
        </div>
    );
}

export default Layout;